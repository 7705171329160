import { Route } from "react-router-dom";
import "../style/contact.css";

function Contact() {
    document.title = Route.title = "Contact";
    return (
        <html>
            <body>
                <h1>Contact</h1>
            </body>
        </html>

    )
}

export default Contact;