import { Route } from "react-router-dom";
import "../style/howitworks.css";

function HowItWorks() {
    document.title = Route.title = "Hoe werkt het";
    return (
        <html>
            <body>
                <h1>Hoe werkt het</h1>
            </body>
        </html>

    )
}

export default HowItWorks;