import { Route } from "react-router-dom";
import "../style/privacy.css";

function Privacy() {
    document.title = Route.title = "Privacy";
    return (
        <html>
            <body>
                <h1>Privacy</h1>
            </body>
        </html>

    )
}

export default Privacy;