import * as React from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import BottomNavigation from "@mui/material/BottomNavigation";
import Paper from "@mui/material/Paper";

export default function FixedBottomNavigation() {
  return (
    <Box sx={{ pb: 7 }}>
      <CssBaseline />
      <Paper
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
        elevation={3}
      >
        <BottomNavigation showLabels style={{ background: "#1976d2" }}>
          <h3 style={{color: "#fff"}}>TicketWallet - Copyright</h3>
        </BottomNavigation>
      </Paper>
    </Box>
  );
}