import { Route } from "react-router-dom";
import "../style/home.css";
import homeimg from "../image/homeimage.jpg";
import trustpilot from "../image/trustpilot.png";

function Home() {
    document.title = Route.title = "Home";
    return (
        <html>
            <body>
            <div class="img-container">
                <img class="homeimg" src={homeimg} alt="Auto" />
                <div class="centered">
                    <h1>TicketWallet</h1>
                </div>
            </div>
            <div className="info">
                <div className="list">
                   <ul>
                        <li>Het veiligste platform ooit! sadsadasdasasd</li>
                        <li>Voeg je tickets toesadsadsadsad</li>
                        <li>Werkt met +10000 evenementenasdsads</li>
                   </ul>
                </div>
                <div className="skyrimg">
                    <img className="skyr" src={trustpilot} alt="img"/>
                </div>
            </div>
                <hr className="line" />
            <div className="info">
                <h1>test</h1>
            </div>
            </body>
        </html>

    )
}

export default Home;