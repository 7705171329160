import * as React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Home from "./Home";
import Privacy from "./Privacy";
import Contact from "./Contact";
import HowItWorks from "./HowItWorks";

function App() {
  return (
  <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/howitworks" element={<HowItWorks />} />
        
      </Routes>
      <Footer/>
    </BrowserRouter>
  );
}

export default App;
